import React from 'react';
import MUKSrc from '../assets/muk/img/logo.png';
import MUQSrc from '../assets/muq/img/logo.jpg';
import WEWOSrc from '../assets/wewo/img/logo.png';
import ORGASrc from '../assets/orga/img/logo.jpg';
import TREXSrc from '../assets/trex/img/logo.png';
import GRTSrc from '../assets/grt/img/logo.svg';
import ORNSrc from '../assets/orn/img/logo.svg';
import GRWOSrc from '../assets/grwo/img/logo.svg';
import SUPSrc from '../assets/sup/img/logo.png';
import CIGSrc from '../assets/cig/img/logo.png';
import FFSrc from '../assets/ff/img/logo.png';
import DEFAULTSrc from '../assets/default/img/logo.png';
import { PublisherName, useConfig } from '../config';

const lookup: { [key in PublisherName]: string } = {
  default: DEFAULTSrc,
  muq: MUQSrc,
  muk: MUKSrc,
  wewo: WEWOSrc,
  orga: ORGASrc,
  trex: TREXSrc,
  grt: GRTSrc,
  orn: ORNSrc,
  grwo: GRWOSrc,
  sup: SUPSrc,
  cig: CIGSrc,
  ff: FFSrc,
};

const Logo = () => {
  const { code } = useConfig();

  return <img height={32} src={lookup[code]} alt="Logo" />;
};

export default Logo;
