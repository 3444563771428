import { createGlobalStyle, css } from 'styled-components';
import { publisher } from '../config';
import { system } from '../theme';

import OpenSansRegular from '../assets/font/opensans/OpenSans-Regular.ttf';
import OpenSansSemiBold from '../assets/font/opensans/OpenSans-SemiBold.ttf';

import SourceSansProLight from '../assets/font/source-sans-pro/SourceSansPro-Light.ttf';
import SourceSansProSemibold from '../assets/font/source-sans-pro/SourceSansPro-Semibold.ttf';

import RobotoSlabRegular from '../assets/font/Roboto_Slab/static/RobotoSlab-Light.ttf';
import RobotoSlabBold from '../assets/font/Roboto_Slab/static/RobotoSlab-SemiBold.ttf';

import IBMPlexSansRegular from '../assets/font/IBMPlexSans/IBMPlexSans-Regular.ttf';
import IBMPlexSansBold from '../assets/font/IBMPlexSans/IBMPlexSans-Bold.ttf';

import Helvetica from '../assets/font/Helvetica-Font/Helvetica.ttf';
import HelveticaBold from '../assets/font/Helvetica-Font/Helvetica-Bold.ttf';

const DEFAULT = css`
  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansRegular});
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansSemiBold});
    font-weight: 600;
  }
`;

const MUK = css`
  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansRegular});
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansSemiBold});
    font-weight: 600;
  }
`;

const MUQ = css`
  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansRegular});
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansSemiBold});
    font-weight: 600;
  }
`;

const WEWO = css`
  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansRegular});
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansSemiBold});
    font-weight: 600;
  }
`;

const TREX = css`
  @font-face {
    font-family: 'Source Sans Pro';
    src: url(${SourceSansProLight});
  }

  @font-face {
    font-family: 'Source Sans Pro';
    src: url(${SourceSansProSemibold});
    font-weight: 600;
  }
`;

const GRT = css`
  @font-face {
    font-family: 'IBM Plex Sans';
    src: url(${IBMPlexSansRegular});
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    src: url(${IBMPlexSansBold});
    font-weight: 600;
  }
`;

const ORN = css`
  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansRegular});
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansSemiBold});
    font-weight: 600;
  }
`;

const GRWO = css`
  @font-face {
	font-family: 'IBM Plex Sans';
	src: url(${IBMPlexSansRegular});
  }

  @font-face {
	font-family: 'IBM Plex Sans';
	src: url(${IBMPlexSansBold});
    font-weight: 600;
  }
`;

const SUP = css`
  @font-face {
    font-family: 'Roboto Slab';
    src: url(${RobotoSlabRegular});
  }

  @font-face {
    font-family: 'Roboto Slab';
    src: url(${RobotoSlabBold});
    font-weight: 600;
  }
`;

const CIG = css`
  @font-face {
    font-family: 'Roboto Slab';
    src: url(${RobotoSlabRegular});
  }

  @font-face {
    font-family: 'Roboto Slab';
    src: url(${RobotoSlabBold});
    font-weight: 600;
  }
`;

const FF = css`
  @font-face {
	font-family: 'Helvetica';
    src: url(${Helvetica});
  }

  @font-face {
    font-family: 'Helvetica';
    src: url(${HelveticaBold});
    font-weight: 600;
  }
`;

const Global = createGlobalStyle`
  ${(publisher === undefined || publisher === 'default') && DEFAULT}
  ${publisher === 'muk' && MUK}
  ${publisher === 'muq' && MUQ}
  ${publisher === 'wewo' && WEWO}
  ${publisher === 'trex' && TREX}
  ${publisher === 'grt' && GRT}
  ${publisher === 'orn' && ORN}
  ${publisher === 'grwo' && GRWO}
  ${publisher === 'sup' && SUP}
  ${publisher === 'cig' && CIG}
  ${publisher === 'ff' && FF}

  html, body {
    font-family: ${system.fontFamily()};
    background-color: ${system.colors('background')};
    font-size: ${system.fontSizes(2)};
    line-height: 20px;
    color:${system.colors('text')};
  }

  button, a {
    font-family: ${system.fontFamily()};
  }

  *:focus {
    outline: none;
  }

  p:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  p:first-of-type {
    padding-top: 0;
    margin-top: 0;
  }

  p {
    margin-top: 0;
  }
`;

export default Global;
