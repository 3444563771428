import { Theme } from 'styled-system';
import * as system from './utils';
import { PublisherName } from '../config';

const primaryColors = {
	muk: '#00a7e6',
	muq: '#e61c52',
	wewo: '#007bc2',
	default: '#e61c52',
	orga: '#E60000',
	trex: '#000000',
	orn: '#52ae32',
	grt: '#021930',
	grwo: '#009fe3',
	sup: '#e30916',
	cig: '#9b8779',
	ff: '#db001c',
}

const defaultTheme: Theme = {
  colors: {
    primary: primaryColors.default,
    secondary: '#b4b1b0',
    text: '#2c2c2c',
    background: '#f5f5f5',
    box: {
      background: '#ffffff',
    },
    input: {
      border: '#c8c8c8',
      background: '#ffffff',
      borderfocus: 'black',
    },
    button: {
      background: primaryColors.default,
      border: primaryColors.default,
      color: 'white',
    },
    localeSwitch: {
      background: primaryColors.default,
      color: 'white',
      bordercolor: primaryColors.default,
    },
  },
  fonts: {
    default: `'Open Sans', "Segoe UI", Tahoma, sans-serif;`,
  },
  fontSizes: [11, 12, 14, 16, 24],
  fontWeights: [400, 600],
  radii: [2, 4],
  borders: {
    localeSwitch: {
      borderstyle: 'none',
      borderwidth: '1px',
      borderradius: '4px',
    },
  	textButton: {
  	  borderbottomwidth: 'medium',
  	  borderbottomstyle: 'none',
  	  textdecoration: 'none',
  	},
  },
};

const mukTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.muk,
    secondary: '#ee684b',
    text: '#2c2c2c',
    background: '#f5f5f5',
    box: {
      background: '#ffffff',
    },
    input: {
      border: '#c8c8c8',
      background: '#ffffff',
      borderfocus: 'black',
    },
    button: {
      background: primaryColors.muk,
      border: primaryColors.muk,
      color: 'white',
    },
    localeSwitch: {
      background: primaryColors.muk,
      bordercolor: primaryColors.muk,
    },
  },
  fonts: {
    default: `'Open Sans', "Segoe UI", Tahoma, sans-serif;`,
  },
  fontSizes: [11, 12, 14, 16, 24],
  fontWeights: [400, 600],
  radii: [2, 4],
};

const muqTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.muq,
    text: '#2c2c2c',
    background: '#f5f5f5',
    box: {
      background: '#ffffff',
    },
    input: {
      border: '#c8c8c8',
      background: '#ffffff',
      borderfocus: 'black',
    },
    button: {
      background: primaryColors.muq,
      border: primaryColors.muq,
    },
    localeSwitch: {
      background: primaryColors.muq,
      bordercolor: primaryColors.muq,
    },
  },
  fonts: {
    default: `'Open Sans', "Segoe UI", Tahoma, sans-serif;`,
  },
  fontSizes: [11, 12, 14, 16, 24],
  fontWeights: [400, 600],
  radii: [2, 4],
};

const orgaTheme: Theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: primaryColors.orga,
    secondary: '#e6007e',
    button: {
      background: primaryColors.orga,
      border: primaryColors.orga,
    },
    localeSwitch: {
      background: primaryColors.orga,
      bordercolor: primaryColors.orga,
    },
  },
  fonts: {
    default: `volte, helvetica, arial, sans-serif;`,
  },
};

const trexTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.trex,
    secondary: '#b4b1b0',
    text: primaryColors.trex,
    background: '#ffffff',
    box: {
      background: '#ffffff',
    },
    input: {
      border: primaryColors.trex,
      background: '#ffffff',
      borderfocus: 'black',
    },
    button: {
      background: 'transparent',
      border: primaryColors.trex,
      color: primaryColors.trex,
    },
    localeSwitch: {
      background: 'transparent',
      color: primaryColors.trex,
      bordercolor: primaryColors.trex,
    },
  },
  fonts: {
    default: `'Source Sans Pro';`,
  },
  fontSizes: [14.8, 14.8, 14.8, 13, 34],
  fontWeights: [400, 600],
  radii: [2, 2],
  borders: {
    localeSwitch: {
      borderstyle: 'solid',
      borderwidth: '1px',
      borderradius: '2px',
    },
    textButton: {
  	  borderbottomwidth: '1px',
  	  borderbottomstyle: 'solid',
  	  textdecoration: 'underline'
  	},
  },
};

const grtTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.grt,
    button: {
      background: primaryColors.grt,
      border: primaryColors.grt,
    },
    localeSwitch: {
      background: primaryColors.grt,
      bordercolor: primaryColors.grt,
    },
  },
  fonts: {
    default: `'IBM Plex Sans', 'Open Sans', sans-serif;`,
  },
};

const ornTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.orn,
    secondary: '#7c7c7b',
    button: {
      background: primaryColors.orn,
      border: primaryColors.orn,
    },
    localeSwitch: {
      background: primaryColors.orn,
      bordercolor: primaryColors.orn,
    },
  },
};

const grwoTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.grwo,
    button: {
      background: primaryColors.grwo,
      border: primaryColors.grwo,
    },
    localeSwitch: {
      background: primaryColors.grwo,
      bordercolor: primaryColors.grwo,
    },
  },
  fonts: {
    default: `'IBM Plex Sans', 'Open Sans', sans-serif;`,
  },
};

const supTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.sup,
    button: {
      background: primaryColors.sup,
      border: primaryColors.sup,
    },
    localeSwitch: {
      background: primaryColors.sup,
      bordercolor: primaryColors.sup,
    },
  },
};

const cigTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.cig,
    button: {
      background: primaryColors.cig,
      border: primaryColors.cig,
    },
    localeSwitch: {
      background: primaryColors.cig,
      bordercolor: primaryColors.cig,
    },
  },
};

const ffTheme: Theme = {
  ...defaultTheme,
  colors: {
    primary: primaryColors.ff,
    button: {
      background: primaryColors.ff,
      border: primaryColors.ff,
    },
    localeSwitch: {
      background: primaryColors.ff,
      bordercolor: primaryColors.ff,
    },
  },
  fonts: {
    default: `'Helvetica', 'Open Sans', sans-serif;`,
  },
};

export const themes: { [key in PublisherName]: Theme } = {
  muk: mukTheme,
  muq: muqTheme,
  wewo: defaultTheme,
  orga: orgaTheme,
  trex: trexTheme,
  grt: grtTheme,
  orn: ornTheme,
  grwo: grwoTheme,
  sup: supTheme,
  cig: cigTheme,
  ff: ffTheme,
  default: defaultTheme,
};

export { system };
